import { isPlatformServer, NgOptimizedImage, registerLocaleData } from '@angular/common';
import {
  HttpHandler,
  HttpHandlerFn,
  HttpRequest,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeNlExtra from '@angular/common/locales/extra/nl';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { APP_INITIALIZER, inject, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
  withEventReplay,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Params, RouterStateSnapshot } from '@angular/router';
import { FrontAuthModule, KEYCLOAK_URL } from '@beathletics/auth';
import { SharedApiService, SharedDataService, SharedDataState } from '@beathletics/beathletics-data-state';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';

import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentPageComponent } from './content-page.component';
import { CustomInputValidatorsDirective } from './shared/custom-input-validators.directive';
import { ThemeColorService } from './shared/theme-color.service';
import { TranslocoRootModule } from './transloco-root.module';
import { StripeState } from './admin/stripe/stripe.state';
import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { fr } from 'date-fns/locale';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeNl, 'nl', localeNlExtra);

export interface RouterStateParams {
  url: string;
  params: Params;
  queryParams: Params;
  datas: { [key: string]: any };
}

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
  serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
      root: { queryParams },
    } = routerState;

    let { root: route } = routerState;
    let datas = { ...routerState.root.data };

    while (route.firstChild) {
      route = route.firstChild;
      datas = { ...datas, ...route.data };
    }

    const { params } = route;

    return { url, params, queryParams, datas };
  }
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([SharedDataState], {
      developmentMode: !environment.production,
      selectorOptions: { suppressErrors: false },
    }),
    AppRoutingModule,
    //  NgxsStoragePluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    ...environment.devToolsModules,
    NgxsSelectSnapshotModule.forRoot(),
    //NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    FrontAuthModule,
    TranslocoRootModule,
    NgOptimizedImage,
    ContentPageComponent,
    CustomInputValidatorsDirective,
  ],
  providers: [
    { provide: KEYCLOAK_URL, useValue: environment.keycloakUrl },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    ThemeColorService,
    { provide: LOCALE_ID, useValue: 'fr-BE' },
    SharedDataService,
    SharedApiService,
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideShareButtonsOptions(shareIcons()),
    provideClientHydration(
      withEventReplay(),
      withHttpTransferCacheOptions({
        includePostRequests: true,
      }),
    ),
    { provide: MAT_DATE_LOCALE, useValue: fr },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    provideDateFnsAdapter(),
  ],
})
export class AppModule {}
