import { Injectable, NgModule, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterModule, RouterStateSnapshot, Routes, UrlTree } from '@angular/router';
import { AuthGuard } from '@beathletics/auth';
import { Observable } from 'rxjs';
import { ContentPageComponent } from './content-page.component';

import { isPlatformBrowser } from '@angular/common';
import {
  AthleteState,
  EventState,
  NewCompetitionState,
  ResultState,
  SharedDataState,
} from '@beathletics/beathletics-data-state';
import { provideStates } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class EmptyChildRedirect {
  private router = inject(Router);

  canActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (state.url === '/') {
      return this.router.parseUrl('/home');
    }
    return true;
  }
}
const isBrowser = () => isPlatformBrowser(PLATFORM_ID);

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AuthGuard],
    data: {
      neededRole: ['secretary', 'ja', 'homologation', 'admin_lbfa', 'admin'],
    },
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '',
    component: ContentPageComponent,
    canActivate: [EmptyChildRedirect],
    data: { feature: 'base-front' },
    children: [
      {
        path: 'athlete',
        canActivate: [],
        data: { theme: 'athlete' },
        loadChildren: () => import('./athlete/athlete.module').then((m) => m.AthleteModule),
      },
      {
        path: 'club',
        canActivate: [],
        data: { theme: 'club' },
        loadChildren: () => import('./club/club.routes').then((m) => m.ClubRoutingModule),
        // redirectTo: 'home',
      },
      {
        path: 'home',
        canActivate: [],
        data: { theme: 'home' },
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'event',
        canActivate: [],
        data: { theme: 'event' },
        loadChildren: () => import('./event/event.module').then((m) => m.EventModule),
      },
      {
        path: 'calendar',
        canActivate: [],
        data: { theme: 'calendar', type: 'calendar' },
        loadChildren: () => import('./calendar/calendar.module').then((m) => m.CalendarModule),
      },
      {
        path: 'rankings',
        // canActivate: [AppFeaturesGuard],
        data: { theme: 'athlete', feature: 'rankings' },
        loadChildren: () => import('./rankings/rankings.module').then((m) => m.RankingsModule),
      },
      {
        path: 'results',
        canActivate: [],
        data: { theme: 'calendar', type: 'results' },
        loadChildren: () => import('./calendar/calendar.module').then((m) => m.CalendarModule),
      },
      { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ],
  },

  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      //enableTracing: environment.production ? false : true,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      /* remove window for ssr ->  scrollOffset: [0,  window.innerHeight / 2 - window.innerHeight / 10], */
    }),
  ],
  exports: [RouterModule],
  providers: [provideStates([NewCompetitionState, AthleteState, EventState, ResultState, SharedDataState])],
})
export class AppRoutingModule {}
